import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import MainWrapper from './components/Layout/Main/MainWrapper';
import Home from './components/Pages/Home';
import Wooden from './components/Pages/Wooden';
import Plastic from './components/Pages/Plastic';
import Shading from './components/Pages/Shading';
import Garage from './components/Pages/Garage';
import Contact from './components/Pages/Contact';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainWrapper />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'fa-ajtok-ablakok',
        element: <Wooden />,
      },
      {
        path: 'muanyag-ajtok-ablakok',
        element: <Plastic />,
      },
      {
        path: 'arnyekolastechnika',
        element: <Shading />,
      },
      {
        path: 'garazskapuk',
        element: <Garage />,
      },
      {
        path: 'kapcsolat',
        element: <Contact />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
