import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuContext from '../../../store/menu-context';

import MenuItemList from './MenuItemList';

import styles from './MenuBar.module.scss';
import optikoIcon from '../../../assets/optiko_logo_szines.png';
import hamburgerMenuIcon from '../../../assets/icons/hamburger-menu-icon.png';

const MenuBar = () => {
  const menuCtx = useContext(MenuContext);

  const [smallMenuBar, setSmallMenuBar] = useState<boolean>(false);

  const hamburgerMenuHandler = () => {
    menuCtx.toggleMenu();
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setSmallMenuBar(window.scrollY > 200);
    });
  }, []);

  const menuBarClassNames = smallMenuBar
    ? `${styles['menu-bar']} ${styles['menu-bar-small']}`
    : styles['menu-bar'];

  return (
    <nav className={styles.nav}>
      <div className={menuBarClassNames}>
        <Link to="/" type="Link">
          <img
            className={styles.logo}
            src={optikoIcon}
            alt="Optiko Solutions Kft. logó"
          />
        </Link>
        <MenuItemList />
        <button
          className={styles['hamburger-menu-icon']}
          onClick={hamburgerMenuHandler}
        >
          <img src={hamburgerMenuIcon} alt="Hamburger menü Ikon" />
        </button>
      </div>
      <div className={styles['hamburger-menu']}>
        {menuCtx.isHamburgerMenuOpen && (
          <MenuItemList isHamburgerMenu={menuCtx.isHamburgerMenuOpen} />
        )}
      </div>
    </nav>
  );
};

export default MenuBar;
