import MainContent from '../Layout/Main/MainContent';

import styles from './Wooden.module.scss';

const Wooden = () => {
  return (
    <MainContent>
      <h1>Fa ajtók, ablakok</h1>
      <p>Jelenleg feltöltés alatt!</p>
    </MainContent>
  );
};

export default Wooden;
