import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { getReCaptchaSiteKey } from '../../utils/general.utils';

import MainContent from '../Layout/Main/MainContent';
import LoadingIcon from '../Utils/LoadingIcon';

import styles from './Contact.module.scss';

const Contact = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);

  const [emailResponse, setEmailResponse] = useState<{
    status: string;
    message: string;
  }>({
    status: '',
    message: '',
  });

  const [isSending, setIsSending] = useState<boolean>(false);

  // Clear the email response feedback after 5 sec
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (emailResponse.status !== '') {
      timeout = setTimeout(() => {
        setEmailResponse({
          status: '',
          message: '',
        });
      }, 5000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [emailResponse]);

  // triggered by recaptcha field change
  function onChange(token: string | null) {
    setReCaptchaToken(token);
  }

  const onEmailSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setEmailResponse({
      status: '',
      message: '',
    });

    try {
      setIsSending(true);

      const response = await fetch('https://optikosolutions.hu/.netlify/functions/email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, message, reCaptchaToken }),
      });
      const data = await response.json();

      if (response.status === 200) {
        setEmailResponse({
          status: 'ok',
          message: data.message,
        });

        setName('');
        setEmail('');
        setMessage('');
      } else {
        throw new Error(data.message);
      }
    } catch (error: any) {
      setEmailResponse({
        status: 'error',
        message: error.message || 'Üzenet elküldése sikertelen!',
      });
    }

    setIsSending(false);
  };

  return (
    <MainContent>
      <div className={styles.contact}>
        <h1>Kapcsolat</h1>
        <h2>
          Amennyiben szolgáltatásom felkeltette figyelmét, kérem küldjön
          üzenetet:
        </h2>
        <form onSubmit={onEmailSubmit}>
          <input
            type="email"
            className={styles['form-input']}
            name="email"
            placeholder="Email..."
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            className={styles['form-input']}
            name="name"
            placeholder="Név..."
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <textarea
            name="message"
            className={styles['form-input']}
            placeholder="Üzenet..."
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {getReCaptchaSiteKey() && (
            <ReCAPTCHA sitekey={getReCaptchaSiteKey()} onChange={onChange} />
          )}
          <button className={styles['submit-button']} type="submit">
            Email küldése
          </button>
        </form>
        {isSending && (
          <div className={styles.loading}>
            <LoadingIcon />
          </div>
        )}
        {emailResponse.status && (
          <p
            className={`${styles['email-response']} ${
              styles[emailResponse.status]
            }`}
          >
            {emailResponse.message}
          </p>
        )}
      </div>
    </MainContent>
  );
};

export default Contact;
