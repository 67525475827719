import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.owner}>Kovács István - tulajdonos, ügyvezető</div>
      <div>Telefonszám: +36 30 391 9102</div>
      <div>Email: optikokft@gmail.com</div>
      <div>© {(new Date().getFullYear())} OptikoSolutions</div>
    </footer>
  );
};

export default Footer;
