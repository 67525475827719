import ReactDOM from 'react-dom/client';
import { MenuContextProvider } from './store/menu-context';

import App from './App';

import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MenuContextProvider>
    <App />
  </MenuContextProvider>
);
