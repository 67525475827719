import { createContext, useState } from 'react';

const MenuContext = createContext({
  isHamburgerMenuOpen: false,
  toggleMenu: (): any => {},
});

export const MenuContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] =
    useState<boolean>(false);
    
  const toggleMenu = () => {
    setIsHamburgerMenuOpen((prevState) => !prevState);
  };

  return (
    <MenuContext.Provider
      value={{
        isHamburgerMenuOpen: isHamburgerMenuOpen,
        toggleMenu: toggleMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContext;
