import MainContent from '../Layout/Main/MainContent';

import styles from './Home.module.scss';
import wideImage from '../../assets/Home/optiko-wide-background.jpg';
import homeImage from '../../assets/Home/Lakeside-Mountain-Modern-Home-Alexander-Design-Group-25-1-Kindesign.jpg';

const Home = () => {
  return (
    <div className={styles['main-container']}>
      <img src={wideImage} alt="Modern ház Optikó logóval" />
      <MainContent>
        <div className={styles.container}>
          <div className={styles['text-container']}>
            <h1>Azonnali fa és műanyag nyílászáró megoldások</h1>
            <p>
              Az Optiko Solutions Kft. fa és műanyag nyílászárók, beltéri ajtók,
              és egyéb kiegészítők termékek (párkányok, légbevezetők…stb)
              forgalmazásával, beépítésével foglalkozik. Az építéskor, vagy
              felújításkor használt alapanyagokkal szemben a mindennapi
              használat komoly követelményeket támaszt. Cégünk azoknak kínál
              megoldásokat, akik szeretnék ingatlanukat olyan minőségi
              nyílászárókkal felszerelni, amely esztétikailag is megállja a
              helyét, műszakilag kifogástalan és az átlagnál nagyobb komfortot
              nyújt. Mi abban segítünk, hogy ügyfeleink igényeit figyelembe véve
              a legoptimálisabb megoldásokat nyújtsuk álmaik megvalósításához.
              Cégünk a nyílászárók terén minden igényre ideális megoldást kínál
              az egyéni design mellett, a legjobban keresett, és a legjobb áru,
              valamint a felsőkategóriás termékeivel egyaránt.
              <br /> Az egyik legfontosabb erősségünk, hogy áraink a kiváló
              minőség mellett is kedvezőek maradnak.
            </p>
            <p>
              Köszönjük, hogy érdeklődésével megtisztelte cégünket, jövőbeni
              kapcsolatunkban bízva, tisztelettel:
            </p>
            <p>
              Kovács István
              <br />
              <em>tulajdonos, ügyvezető</em>
            </p>
          </div>
          <div className={styles['img-container']}>
            <img src={homeImage} alt="Emeletes ház tó partján" />
          </div>
        </div>
      </MainContent>
    </div>
  );
};

export default Home;
