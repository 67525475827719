import { useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import MenuContext from '../../../store/menu-context';

import styles from './MenuItem.module.scss';

interface MenuItemProps {
  children: React.ReactNode;
  to: string;
}

const MenuItem = ({ children, to }: MenuItemProps) => {
  const navigate = useNavigate();

  const menuCtx = useContext(MenuContext);

  const [hovered, setHovered] = useState<boolean>(false);

  const handleContainerClick = () => {
    navigate(to);
    menuCtx.isHamburgerMenuOpen && menuCtx.toggleMenu();
  };

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={styles['nav-link-container']}
      onClick={handleContainerClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles['link-container']}>
        <NavLink
          to={to}
          className={({ isActive }) => {
            return isActive || hovered ? styles.active : '';
          }}
        >
          <span>{children}</span>
        </NavLink>
      </div>
    </div>
  );
};

export default MenuItem;
