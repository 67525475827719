import MainContent from '../Layout/Main/MainContent';

import styles from './Plastic.module.scss';
import interiorLivingRoomImage from '../../assets/Plastic/optico_house_interior_livingroom.jpg';
import diszpanelesAjtoImage1 from '../../assets/Plastic/diszpaneles_ajtok1.jpg';
import diszpanelesAjtoImage2 from '../../assets/Plastic/diszpaneles_ajtok2.jpg';
import diszpanelesAjtoImage3 from '../../assets/Plastic/diszpaneles_ajtok3.jpg';
import valodiOsztosAjtoImage1 from '../../assets/Plastic/valodi_osztos_ajtok1.jpg';
import valodiOsztosAjtoImage2 from '../../assets/Plastic/valodi_osztos_ajtok2.jpg';
import valodiOsztosAjtoImage3 from '../../assets/Plastic/valodi_osztos_ajtok3.jpg';
import salamander82AblakImage1 from '../../assets/Plastic/ablak-salamander_blue_Evo_82MD.jpg';
import salamander82AblakImage2 from '../../assets/Plastic/ablak-salamander_blue_Evo_82MD_meret.jpg';
import salamander73AblakImage1 from '../../assets/Plastic/ablak-salamander_blue_Evo_73MD.jpg';
import salamander73AblakImage2 from '../../assets/Plastic/ablak-salamander_73meret.jpg';

const Plastic = () => {
  return (
    <MainContent>
      <div className={styles.container}>
        <div className={styles['base-info-container']}>
          <div>
            <h1>
              Műanyag <a href="#plastic-door">ajtók</a>,{' '}
              <a href="#plastic-window">ablakok</a>
            </h1>
            <p>
              A német Salamander profilgyár prémium minőségű műanyag
              ablakai-ajtói a legújabb generációt képviseli a műanyag
              nyílászárók világában. Mert nemcsak szépek, stílusosak és több
              színben is megvásárolhatók, de minőségben is verhetetlenek.
            </p>
            <p>
              Az esztétikára sem lehet panaszunk, hiszen bár a minimalista
              design korát éljük, és mindenki a letisztult egyenes vonalvezetést
              preferálja, mégis van egy nagy réteg, aki inkább a praktikusabb
              lekerekített formákat kedveli. A Salamander ablakai ezt a vonalat
              is követik, hiszen az extra sima felület, és a kerekített
              szárnyvezetés révén egy esztétikus, és könnyen tisztítható
              megjelenéssel csábítják el a vásárlókat. A lekerekített külső
              szárny kialakításnak nem csak esztétikai jelentősége van, hanem a
              megfelelő vízelvezetésért is felel, mivel a az íves, kialakítás
              túl ér a külső tömítő kör síkján, a lefolyó csapadék, nem a tömítő
              gumit éri, hanem a tok külső felületére van elvezetve.
            </p>
          </div>
          <img src={interiorLivingRoomImage} alt="Interior of a living room" />
        </div>

        <div id="plastic-door" className={styles['section-separator']} />
        <h1>Műanyag ajtók</h1>
        <h3>Bejárati ajtók</h3>
        <p>
          Bejárati ajtóink az ablakprofiloknak megfelelően Salamander 73
          Bluevolution vagy Salamander Blue Evolution 82MD profilokból
          készülhetnek.
        </p>
        <p>Bejárati ajtókat kettéoszthatjuk:</p>
        <h3>Díszpaneles bejárati ajtók</h3>
        <p>
          Itt kétféle panelokat találhatunk, modern és klasszikus ajtóbetéteket.
          A modern panelek minimál, míg a klasszikus betétek hagyományos
          dizájnhoz illeszkedő megjelenést kölcsönöznek otthonának.
          <br />
          Az esztétikai megjelenés mellett fontos biztonsági szerepe is van az
          ajtóbetétnek. <br /> A panelok felépítése tekintetében lehetnek PVC,
          HPL panelok. <br /> Vagy kérhető MDF, alu vagy acélmerevítés, mely
          láthatatlanul biztonságosabbá teheti bejárati ajtóját.{' '}
        </p>

        <h4>Tekintse meg díszpaneles katalógusunkat!</h4>

        <h4>Egy két példa a díszpanelos ajtókhoz</h4>

        <div className={styles['image-example-container']}>
          <img
            src={diszpanelesAjtoImage1}
            alt="Díszpaneles bejárati ajtó példa 1"
          />
          <img
            src={diszpanelesAjtoImage2}
            alt="Díszpaneles bejárati ajtó példa 2"
          />
          <img
            src={diszpanelesAjtoImage3}
            alt="Díszpaneles bejárati ajtó példa 3"
          />
        </div>

        <h3>Valódi osztós bejárati ajtók</h3>

        <p>
          Ezen bejárati ajtó típust azon érdeklődőknek ajánljuk, akik a nagy
          üvegfelületeket ill. az egyedi megjelenést részesítik előnyben. A
          szárnyosztók különböző elhelyezésével, egyedi ötletek is
          megvalósíthatók. Az osztók által behatárolt területekbe különböző
          hőszigetelt üvegtípusokat (float, katedrál, savmart, reflexiós, stb.)
          és hőszigetelt tömör betéteket tudunk elhelyezni. Az üveg és betét
          vastagság növelésével, a hőszigetelési érték javítható. A fehér szín
          mellett, faerezet fóliával ellátott kivitelek is rendelhetők.
        </p>

        <h4>Egy két példa a valódi osztós ajtókhoz</h4>

        <div className={styles['image-example-container']}>
          <img
            src={valodiOsztosAjtoImage1}
            alt="Valódi osztós bejárati ajtó példa 1"
          />
          <img
            src={valodiOsztosAjtoImage2}
            alt="Valódi osztós bejárati ajtó példa 2"
          />
          <img
            src={valodiOsztosAjtoImage3}
            alt="Valódi osztós bejárati ajtó példa 3"
          />
        </div>

        <div id="plastic-window" className={styles['section-separator']} />
        <h1>Műanyag ablakok</h1>
        <h3>Típus: Salamander Blue Evolution 82MD</h3>
        <p>
          82 mm-es beépítési mélységű, 6 kamrás PVC profilrendszer, melyben a
          középtömítés kialakításával háromszoros körbefutó gumitömítés
          található. Ezen tulajdonságok, valamint az akár 48 mm ig terjedő
          üvegezés és a meleg perem biztosítja a nyílászárók kiemelkedően magas
          energiahatékonyságát.
        </p>
        <ul>
          <li>
            82 mm-es beépítési mélység ideális mind az új építésű ingatlanoknál,
            mind a felújítandó épületeknél
          </li>
          <li>6 kamrás tok - 6 kamrás szárny</li>
          <li>kiváló hőszigetelés</li>
          <li>
            Háromszoros körbefutó tömítőgumi ( MD profil esetében), a jobb hő-
            és hangszigetelés érdekében
          </li>
          <li>Vasalat német ROTO NT rendszer</li>
          <li>
            Válaszható üvegezési vastagság: 24-52 mm-ig Két illetve három rétegű
            üvegek, akár Uw = 0,73 W/m²K teljes hőátbocsátási tényező is
            elérhető!
          </li>
          <li>hibásműködtetés-gátló, résszellőző</li>
          <li>Kiváló ár-érték arány jellemzi</li>
          <li>Tartozékok: kilincs, zár- zárbetétek, takarók</li>
          <li>Faerezetes és színes kivitelben is kapható</li>
        </ul>

        <div className={styles['image-example-container']}>
          <img
            src={salamander82AblakImage1}
            alt="Salamander Blue Evolution 82MD műanyag nyílászáró"
          />
          <img
            src={salamander82AblakImage2}
            alt="Salamander Blue Evolution 82MD műanyag nyílászáró méretek"
          />
        </div>

        <h3>Típus: Salamander 73 Bluevolution műanyag nyílászáró</h3>
        <p>
          73 mm-es beépítési mélységű, 5 kamrás PVC profilrendszer, mely a két-
          vagy háromrégetű üvegezésnek köszönhetően akár 46 dB hanggátlással is
          bír. Energiahatékony, betörésvédelemmel ellátott nyílászáró, mely
          tökéletes ár-érték arányt képvisel.
        </p>
        <ul>
          <li>
            Beépítési mélység: 73 mm-es beépítési mélység ideális mind az új
            építésű ingatlanoknál, mind a felújítandó épületeknél kiváló
            hőszigetelés az 5 légkamrának köszönhetően
          </li>
          <li>5 kamrás tok - 5 kamrás szárny</li>
          <li>Dupla, UV-álló ütköző gumitömítéssel</li>
          <li>Vasalat német ROTO NT rendszer</li>
          <li>
            Válaszható üvegezési vastagság: 24-44 mm-ig Két illetve három rétegű
            üvegek, akár Uw = 0,85 W/m²K teljes hőátbocsátási tényező is
            elérhető!
          </li>
          <li>Hibásműködtetés-gátló, résszellőző</li>
          <li>Tartozékok: kilincs, zár- zárbetétek, takarók</li>
          <li>Faerezetes és színes kivitelben is kapható</li>
          <li>Kiváló ár-érték arány jellemzi</li>
        </ul>
        <div className={styles['image-example-container']}>
          <img
            src={salamander73AblakImage1}
            alt="Salamander 73 Bluevolution műanyag nyílászáró"
          />
          <img
            src={salamander73AblakImage2}
            alt="Salamander 73 Bluevolution műanyag nyílászáró méretek"
          />
        </div>

        <h2>
          Új műanyag ablakai mellé vásároljon redőny vagy szúnyoghálót. Tekintse
          meg árnyékolástechnikai eszköz kínálatunkat is.
        </h2>
      </div>
    </MainContent>
  );
};

export default Plastic;
