import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import MenuContext from '../../../store/menu-context';

import MenuBar from '../Menu/MenuBar';
import Footer from '../Footer';

import styles from './MainWrapper.module.scss';
import toTopImage from '../../../assets/icons/up-icon.png';

const MainWrapper = () => {
  const menuCtx = useContext(MenuContext);

  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setShowTopBtn(window.scrollY > 200);
    });
  }, []);

  const handleOnMainClick = () => {
    menuCtx.isHamburgerMenuOpen && menuCtx.toggleMenu();
  };

  const toTopHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <MenuBar />
      <div className={styles.main} onClick={handleOnMainClick}>
        <Outlet />
        {showTopBtn && (
          <div className={styles['back-to-top']}>
            <button onClick={toTopHandler}>
              <img src={toTopImage} alt="Scroll to top icon" />
            </button>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MainWrapper;
