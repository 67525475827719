import MenuItem from './MenuItem';

import styles from './MenuItemList.module.scss';

interface MenuProps {
  isHamburgerMenu?: boolean;
}

const MenuItemList = ({ isHamburgerMenu }: MenuProps) => {
  return (
    <div
      className={
        !isHamburgerMenu ? styles['menu-items'] : styles['hamburger-menu-items']
      }
    >
      <MenuItem to={'/'}>Főoldal</MenuItem>
      <span className={styles.separator} />
      <MenuItem to={'/fa-ajtok-ablakok'}>Fa ajtók, ablakok</MenuItem>
      <span className={styles.separator} />
      <MenuItem to={'/muanyag-ajtok-ablakok'}>Műanyag ajtók, ablakok</MenuItem>
      <span className={styles.separator} />
      <MenuItem to={'/arnyekolastechnika'}>Árnyékolástechnika</MenuItem>
      <span className={styles.separator} />
      <MenuItem to={'/garazskapuk'}>Garázskapuk</MenuItem>
      <span className={styles.separator} />
      <MenuItem to={'/kapcsolat'}>Kapcsolat</MenuItem>
    </div>
  );
};

export default MenuItemList;
