import MainContent from '../Layout/Main/MainContent';

import styles from './Garage.module.scss';

const Garage = () => {
  return (
    <MainContent>
      <h1>Garázskapuk</h1>
      <p>Jelenleg feltöltés alatt!</p>
    </MainContent>
  );
};

export default Garage;
